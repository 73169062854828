import React, {createContext, useState} from 'react';
import { createBrowserRouter, RouterProvider }  from 'react-router-dom';
import { LayoutLogin } from './layouts/Login/LayoutLogin';
import { LayoutDashboard } from './layouts/Dashboard/LayoutDashboard';
// Views
import { Login } from './pages/Login/Login';
import { Markets } from './pages/Market/Markets';
import { Register } from './pages/Register/Register';
import { Trader } from './pages/Trader/Trader';

export const AccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState([]);
  return(
    <AccountContext.Provider value={{ account, setAccount}}>
      {children}
    </AccountContext.Provider>
  );
};

const router = createBrowserRouter([
  {
    path:"/",
    element: <LayoutLogin><Login/></LayoutLogin>
  },
  {
    path:"/register",
    element: <LayoutLogin><Register/></LayoutLogin>
  },
  {
    path:"/trader",
    element: <LayoutDashboard><Trader/></LayoutDashboard>
  },
  {
    path:"/market",
    element: <LayoutDashboard><Markets/></LayoutDashboard>
  },
  {
    path:"/transactions",
    element: <LayoutDashboard></LayoutDashboard>
  },
  {
    path:"/world",
    element: <LayoutDashboard></LayoutDashboard>
  }
])

export const App = () => {
  return (
    <>
    <AccountProvider>
      <RouterProvider 
        router={router}
      />
    </AccountProvider>
    </>
  );
};