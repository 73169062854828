import React from 'react';
import { Header } from '../../components/main/Header';
import { Footer } from '../../components/main/Footer';
import './Login.css'

export const LayoutLogin = ({ children }) => (
  <>
  <div className='body-grid'>
    {children}
  </div>
  </>
);