import React, { useContext, useState } from 'react';
import axios from 'axios';
import { AccountContext } from '../App';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const AccountApi = () => {
  const { setAccount } = useContext(AccountContext);
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const api = process.env.REACT_APP_IDP_PROVIDER;

  const authenticate = async (login) => {
    if (!login.email || !login.password) {
      throw new Error("Email and password are required");
    }
    try {
      const response = await axios.post(`${api}/session/auth`, login);
      console.log(response)
      if (response.status===200) {
        const data = await response.data;
        localStorage.setItem('token', data);
        navigate('/trader');
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const createAccount = async (account) => {
    if (!account.email || !account.password) {
      throw new Error("Email and password are required");
    }
    try {
      const response = await axios.post(`${api}/account/create`, account);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { authenticate, createAccount };
};
 
export default AccountApi;