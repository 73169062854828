import React, {useContext, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {Form, Col, Row, Button} from 'react-bootstrap';
import { AccountContext } from '../../App';
import { jwtDecode } from 'jwt-decode';
import AccountApi from '../../api/AccountApi';
import sideArt from '../../assets/images/illustration.svg';
import './Login.css';

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const accountApi = AccountApi();
  const [validated, setValidated] = useState(false);
  
  const submitLogin = async (event) => {
    try {
      await accountApi.authenticate({ email, password });
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    var loginData = new FormData(form);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      event.preventDefault();
      await submitLogin(loginData);
    }
    setValidated(true);
  };

  return (
    <>
    <div className='container'>
      <Row>
        <div className='login-container' md="6">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className='mb-3'>
              <Form.Group as={Col} md="12" controlId="email">
                <Form.Control name='email' value={email} type="email" placeholder="Email" onChange={(event) => setEmail(event.target.value)} required/>
                <Form.Control.Feedback type='invalid'>Email address required.</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md="12" controlId="password">
                <Form.Control name='password' value={password} type="password" placeholder="Password" onChange={(event) => setPassword(event.target.value)} required/>
                <Form.Control.Feedback type='invalid'>Password is required.</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Col md="6">
              </Col>
              <Col md="3">
                <Button variant="login">
                  <Link to='/register'>
                    Register
                  </Link>
                </Button>
              </Col>  
              <Col md="3">
                <Button variant="login" type="submit">
                  Login
                </Button>
              </Col>            
            </Row>
          </Form>
        </div>
      </Row>
        
    </div>
    </>
  )

};