import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Bar, ResponsiveContainer } from 'recharts';
import io from 'socket.io-client';

const StockCandleChart = ( {stockSymbol} ) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const socket = io('wss://streamer.finance.yahoo.com');

    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
      subscribeToStock(socket);
    });

    socket.on('message', (newData) => {
      // Assuming newData is in the format: { date: '2024-05-13', open: 100, high: 110, low: 90, close: 105 }
      setData([...data, newData]);
    });

    return () => {
      socket.disconnect();
    };
  }, [data, stockSymbol]);

  const subscribeToStock = (socket) => {
    const subscriptionMessage = {
      subscribe: [`quote_${stockSymbol}`],
    };
    socket.send(JSON.stringify(subscriptionMessage));
  };

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Bar
            dataKey="open"
            data={data}
            fill="#8884d8"
            isAnimationActive={false}
          />
          <Bar
            dataKey="close"
            data={data}
            fill="#82ca9d"
            isAnimationActive={false}
          />
          <Line type="monotone" dataKey="close" stroke="#ff7300" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StockCandleChart;
