import React, {useContext, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {Form, Col, Row, Button} from 'react-bootstrap';
import { AccountContext } from '../../App';
import { jwtDecode } from 'jwt-decode';
import './Register.css';

export const Register = () => {
  const {setAccount} = useContext(AccountContext);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  //IDP endpoint
  const api = process.env.REACT_APP_IDP_PROVIDER;
  const accountEndpoint = `${api}/account/create/`;
  
  const submitRegister = async (formData) => {
    const registerData = {
      givenName: formData.get('given'),
      familyName: formData.get('family'),
      emailAddress: formData.get('email'),
      password: formData.get('password'),
      currency: "USD"
    };
    try {
      const response = await fetch(accountEndpoint,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registerData),
      });
      console.log(response)
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data);
        const employee = jwtDecode(data);
        setAccount(employee);
        navigate('/companies');
      } else if (response.status === 401) {
        navigate('/');
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    var registerData = new FormData(form);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      event.preventDefault();
      await submitRegister(registerData);
    }
    setValidated(true);
  };

  return (
    <>
    <div className='container'>
      <Row>
      </Row>
    </div>
    </>
  )

};