import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from '../elements/Logo';
import WorldTraderNav from './WorldTraderNav/WorldTraderNav';
import { jwtDecode } from 'jwt-decode';
import { Dropdown, ButtonGroup } from 'react-bootstrap';

export const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );
  const [identity, setIdentity] = useState({});

  useEffect(() => {
    const getStoredToken = () => {
      return localStorage.getItem('token');
    };
    const storedToken = getStoredToken();
    const fetchAccount = async () => {
      try {
        console.log("account: ", storedToken);
        const identity = jwtDecode(storedToken);
        setIdentity(identity);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    if (storedToken) {
      fetchAccount();
    }
  }, [])
    

  return (
    <header
      {...props}
      className={classes}
    >
      <ul className='nav flex-column'>
        <Link className='nav-button' to='/market'>
          <div className='btn-outline-info btn-pages'>
            <div className='hidden-sm-down'>Home</div>
          </div>
        </Link>
        <Link className='nav-button' to='/market'>
          <div className='btn-outline-info btn-pages'>
            <div className='hidden-sm-down'>Market</div>
          </div>
        </Link>
        <Link className='nav-button' to='/transactions'>
          <div className='btn-outline-info btn-pages'>
            <div className='hidden-sm-down'>Transactions</div>
          </div>
        </Link>
        <Link className='nav-button' to='/trader'>
          <div className='btn-outline-info btn-pages'>
            <div className='hidden-sm-down'>Trader</div>
          </div>
        </Link>
        <Link className='nav-button' to='/world'>
          <div className='btn-outline-info btn-pages'>
            <div className='hidden-sm-down'>World</div>
          </div>
        </Link>
      </ul>
    </header>
  );
}