import React, {useState, createContext} from 'react';
import { Header } from '../../components/dashboard/Header';
import { Footer } from '../../components/dashboard/Footer';
import './Dashboard.css';
import { Navbar } from 'react-bootstrap';

export const LiveDataContext = createContext();
export const MarketContext = createContext();
export const TraderContext = createContext();

// Create Live Data Provider
const LiveDataProvider = ({ children }) => {
  const [liveData, setLiveData] = useState([]);
  return(
    <LiveDataContext.Provider value={{ liveData, setLiveData}}>
      {children}
    </LiveDataContext.Provider>
  );
};

const MarketProvider = ({ children }) => {
  const [liveData, setLiveData] = useState([]);
  return(
    <MarketContext.Provider value={{ liveData, setLiveData}}>
      {children}
    </MarketContext.Provider>
  );
};

const TraderProvider = ({ children }) => {
  const [liveData, setLiveData] = useState([]);
  return(
    <TraderContext.Provider value={{ liveData, setLiveData}}>
      {children}
    </TraderContext.Provider>
  );
};

export const LayoutDashboard = ({ children }) => (
  <>
  <div className='body-grid'>
    <LiveDataProvider>
      <MarketProvider>
        <TraderProvider>
          <div className='trade-container'>
            <div className='row  g-0'>
              <div className='col-md-2'>
                <div className='nav-content'>
                  <Header/>
                </div>
              </div>
              <div className='col-md-10'>
                <Navbar></Navbar>
                {children}
              </div>
            </div>
          </div>
        </TraderProvider>
      </MarketProvider>
    </LiveDataProvider>
  </div>
  </>
);