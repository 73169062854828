import React, { useEffect, useState,  } from 'react'
import StockCandleChart from '../../../components/elements/Charts/StockCandleChart';
const TraderSelected = (trader) => {

  return (
    <>
      <StockCandleChart stockSymbol="AAPL"/>
    </>
  );
}
 
export default TraderSelected;