import React from 'react';
import TraderRow from './components/TraderRow'
import TraderSelected from './components/TraderSelected';
import TraderHoldings from './components/TraderHoldings';
import MarketMovers from './components/MarketMovers';
import './Trader.css';

export const Trader = () => {
    return(
    <>
    <div className='trader-grid'>
        <div className='trader-row-1'>
            <TraderRow/>
        </div>
        <div className='trader-row-2'>
            <div className='row g-0'>
                <div className='col-md-8'><TraderSelected/></div>
                <div className='col-6 col-md-4'><TraderHoldings/></div>
            </div>
        </div>
        <div className='trader-row-3'>
            <div className='row g-0'>
                <MarketMovers/>
            </div>
        </div>
    </div>
    </>
)};